import { ApiService } from '@ApiService/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StatusTextSearch } from '@Models/search/status-text-search';

@Injectable({
  providedIn: 'root',
})
export class ServiceBase<T> implements IService<T> {
  protected _urlPath: string;
  protected _apiService: ApiService;

  //Subscription for Edit/Save Mode Buttons
  protected editModeSource = new BehaviorSubject<boolean>(false);
  public Edit$ = this.editModeSource.asObservable();

  protected saveSource = new BehaviorSubject<boolean>(false);
  public Save$ = this.saveSource.asObservable();

  //Subscription for loading data
  protected dataDidLoadSource = new BehaviorSubject<boolean>(false);
  public dataDidLoad$ = this.dataDidLoadSource.asObservable();

  //Generic List for Records fetched from API

  protected SelectedIDSource = new BehaviorSubject<number>(null);
  public SelectedID$ = this.SelectedIDSource.asObservable();

  protected FilterStateSource = new BehaviorSubject<StatusTextSearch>({});
  public FilterState$ = this.FilterStateSource.asObservable();

  get API(): ApiService {
    return this.api_Service;
  }

  constructor(private api_Service: ApiService, endpoint: string) {
    this._urlPath = endpoint;
    this._apiService = api_Service;
  }

  /*
   *
   * Generic API calls
   */
  FetchByID(id: number, args?: any): Promise<T> {
    if (!args) {
      return this._apiService.readByID(this._urlPath, id).toPromise();
    }
    return this._apiService.readByID(this._urlPath, id, args).toPromise();
  }

  FetchByGuid(id: string): Promise<T> {
    return this._apiService.read(this._urlPath + `/${id}`).toPromise();
  }

  FetchAll(): Promise<T> {
    return this._apiService.read(this._urlPath).toPromise();
  }

  FetchByCustomPath(path: string, id: any): Promise<T> {
    return this._apiService.readByID(this._urlPath + path, id).toPromise();
  }

  /*
   *
   * Fetch Records StatusSearchText Model {Text: , Status}
   */
  FetchByFilter(filters: any): Promise<T> {
    const args = Object.assign({}, filters);
    if (args.status === -1) {
      delete args.status;
    }
    return this._apiService.read(this._urlPath, args).toPromise();
  }

  FetchByFilterSync(filters: any): Promise<T> {
    const args = Object.assign({}, filters);
    if (args.status === -1) {
      delete args.status;
    }
    return this._apiService.read(this._urlPath, args).toPromise();
  }

  Search(filters: any): Promise<T> {
    return this._apiService.search(this._urlPath + '/search', filters).toPromise();
  }

  Translate(filters: any): Promise<T> {
    return this._apiService.translate(this._urlPath, filters).toPromise();
  }

  Update(item: T): Promise<T> {
    return this._apiService.update(this._urlPath, item).toPromise();
  }

  Insert(newItem: any, customEndpoint?: string): Promise<T> {
    return this._apiService.create(customEndpoint !== undefined ? this._urlPath + customEndpoint : this._urlPath, newItem).toPromise();
  }

  Delete(item: any): Promise<T> {
    return this._apiService.delete(this._urlPath, item).toPromise();
  }

  DeleteByItem(item: any): Promise<T> {
    return this._apiService.deleteObj(this._urlPath, item).toPromise();
  }

  DeleteByID(id: number): Promise<T> {
    return this._apiService.delete(this._urlPath, id).toPromise();
  }

  DeleteByGuid(id: string): Promise<T> {
    return this._apiService.delete(this._urlPath, id).toPromise();
  }
  /**
   * GET
   */

  SetSelectedID(id: number): void {
    this.SelectedIDSource.next(id);
    this.FetchByID(id);
  }

  OpenEditMode(): void {
    this.editModeSource.next(true);
  }
  CloseEditMode(): void {
    this.editModeSource.next(false);
  }
  SaveData(): void {
    this.saveSource.next(true);
    this.CloseEditMode();
    this.saveSource.next(false);
  }

  CancelSave(): void {
    this.saveSource.next(false);
  }

  SetFilters(filters: any) {
    this.FilterStateSource.next(filters);
  }

  GetFilters() {
    return this.FilterStateSource.getValue();
  }
}

export interface IService<T> {
  API: ApiService;
  Save$: Observable<boolean>;
  Edit$: Observable<boolean>;
  FilterState$: Observable<StatusTextSearch>;
  SelectedID$: Observable<number>;

  FetchByID(id: any, args?: any): Promise<T>;
  FetchByGuid(id: string): Promise<T>;
  FetchAll(): Promise<T>;
  FetchByCustomPath(path: string, id: any): Promise<T>;
  FetchByFilter(filters: any): Promise<T>;
  FetchByFilterSync(filters: any): Promise<any>;
  Update(item: any): Promise<T>;
  Insert(newItem: any): Promise<T>;
  Delete(item: any): Promise<T>;
  DeleteByID(id: number): Promise<T>;
  DeleteByGuid(id: string): Promise<T>;
  DeleteByItem(item: any): Promise<T>;
  Search(filters: any): Promise<T>;
  Translate(filters: any): Promise<T>;

  OpenEditMode(): void;
  CloseEditMode(value: boolean): void;
  SaveData(): void;
  CancelSave(): void;

  SetSelectedID(id: number): void;
  SetFilters(filters: any): void;
}

export interface IResponseObject {
  ErrorMessage: string;
  ErrorNumber: number;
  InsertID: number;
  RowsAffected: number;
  Success: boolean;
  InsertGuidID: string;
  InsertedItem: any;
}

export interface ReloadOptions {
  ReloadAll?: boolean;
  ReloadByID?: boolean;
  ReloadWithFilter?: boolean;
  Filter?: any;
}
