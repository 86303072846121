export enum AUTH {
  LOGIN = 'auth/login',
  PASSWORDRESET = 'auth/resetpassword',
  CHANGEPASSWORD = 'auth/changepassword',
  INSERT = 'auth/insert',
  UPDATE = 'auth/update',
}

export enum CUSTOMER {
  AICCCLIENTID = 'customeraicc',
  CUSTOMERS = 'customer',
  CUSTOMERCONTACT = 'customercontact',
  CUSTOMERDOWNLOADACCOUNT = 'customerdownloadaccount',
  CUSTOMERWRAPS = 'customerwraps',
  SALESREP = 'customersalesrep',
  TRANSACTION = 'lessonstocustomer',
  SEARCH_TRANSACTION = 'lessonstocustomer/search',
  CUSTOMERSEARCH = 'customer/GetCustomers',
}

export enum EXAM {
  EXAMS = 'exam',
}

export enum LESSON {
  ABSORBMETADATA = 'absorbmetadata',
  AFFILIATIONS = 'lessonaffiliations',
  BULKKEYWORDINSERT = 'keyword/bulkImport',
  CATALOGLIBRARY = 'cataloglibrary',
  CATALOGSUBTOPIC = 'catalogsubtopic',
  CATALOGTOPIC = 'catalogtopic',
  CEU_TRACKING = 'ceu',
  CLONE = 'clone',
  HSIGROUP = 'hsigroup',
  INSTRUCTIONALDESIGNER = 'instructionaldesigner',
  KEYWORDS = 'keyword',
  LESSONS = 'lessons',
  CEU_ASSOCIATION = 'lessonceu',
  LESSON_THUMBNAILS = 'lessonthumbnail',
  LESSONLOCALES = 'lessonlocale',
  LESSONLOCALE_IMPORT = 'lessonlocale/migratelocale',
  LESSONPLATFORMS = 'lessonplatform',
  LESSONSEARCH = 'lessons/search',
  LIBRARYS = 'libraries',
  LMS = 'lms',
  LOCALES = 'locale',
  OBJECTIVES = 'lessonobjective',
  OTHERSOURCES = 'othersource',
  PROGRAMS = 'programs',
  PROGRAMSWITHLESSONS = 'programswithlessons',
  PROJECTMANAGER = 'projectmanager',
  PUBLISHNOTES = 'lessonpublishnotes',
  REGULATORYREQUIREMENTS = 'regulatoryrequirement',
  SCREENSHOTS = 'lessonscreenshot',
  SECTIONS = 'lessonsection',
  SUBJECTMATTEREXPERTS = 'sme',
  SUPPMATS = 'supplementalmaterials',
  TRANSLATEDOBJECTIVES = 'translatedObjective',
  TRANSLATEDSECTIONS = 'translatedsection',
  VENDORS = 'vendors',
}

export enum REPORTS {
  AUDIT = 'audit',
  LESSON_VERSION_AUDIT = 'lessonversion',
  CCMDR = 'lessonstocustomer/ccmdr',
  DISTRIBUTIONREPORT = 'customer/distributionreport',
  EXPORT_LESSON_THUMBNAILS = 'lessonthumbnail/byCustomerId',
  EXPORT_LESSON_THUMBNAILS_REPORT = 'lessonthumbnail/all/report',
  LMDR = 'libraries/lmdr',
}

export enum RESOURCES {
  SALESLIBRARY = 'saleslibraries',
  SALESLIBRARYASSOCIATIONS = 'saleslibraryseries/series',
  SALESSERIES = 'salesseries',
  STUDYGUIDE = 'resources/studyguide',
}

export enum SYSTEM {
  CONFIGURATION = 'configuration',
  PILLAR = 'log',
  SYSTEMNOTIFICATIONS = 'systemnotification',
  FEATUREFLAGS = 'featureflags'
}

export enum THUMBNAILS {
  Export_Lesson_Thumbnails = 'lessonthumbnail/byCustomerId',
}

export enum TRANSLATIONS {
  TRANSLATE = 'translate',
}

export enum PROFILES {
  ADMINS = 'auth',
  USERS = 'auth/admins',
}

export enum AICC {
  STUDENT_UTILIZATION = 'StudentCourses/EnrollmentsByClient',
  CLIENTS = 'clients',
}

export enum SCORM {
  STUDENT_UTILIZATION = 'ScormDataV2/SCORMUtilizationStudentEnrollments',
  COURSE_UTILIZATION = 'ScormDataV2/SCORMUtilizationCourseEnrollments',
}

export const ApiPath = {
  AICC,
  AUTH,
  CUSTOMER,
  EXAM,
  LESSON,
  PROFILES,
  REPORTS,
  RESOURCES,
  SCORM,
  SYSTEM,
  THUMBNAILS,
  TRANSLATIONS,
};
