import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { AuthStateService } from '@BaseServices/auth-state.service';
import { Location } from '@angular/common'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  //, private userService: UserService
  constructor(
    private router: Router,
    private authStateService: AuthStateService,
    private location: Location
  ) { }

  async canActivate(route: Route) {
    const currentUser = await this.authStateService.getCurrentLoggedInUser();
    const featureFlags = await JSON.parse(sessionStorage.getItem('featureFlags'));

    //Check if User is logged in.
    if (currentUser) {
      if (currentUser.NeedPasswordReset) {
        this.router.navigate(['/error/page-not-found']);
      }

      if (featureFlags && featureFlags.length > 0) {
        await featureFlags.every(flag => {
          if (route.data['component'] && flag.component) {
            if (flag.component.toLowerCase() === route.data['component'].toLowerCase() && flag.isPathActive === false) {
              this.router.navigate(['/error/page-not-found']);
              return false;
            }
          }
          return true;
        })
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }

  async canLoad(route: Route) {

    let shouldLoad: boolean = await true;
    const featureFlags = await JSON.parse(sessionStorage.getItem('featureFlags'));

    if (featureFlags && featureFlags.length > 0) {
      await featureFlags.every(flag => {
        /** Check if application can load module */
        if (flag && flag.module.toLowerCase() === route.data['module'].toLowerCase() && flag.isModuleActive == false) {
          this.router.navigate(['/error/page-not-found']);
          shouldLoad = false;
          return false
        } else {
          return true
        }
      })
    }


    return shouldLoad;
  }
}
